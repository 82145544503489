<template>
  <div>
      <flixDashboard>
        <template v-slot:content>
          <div class="flix-container">
            <flixCheckPermissions role="customer" permission="2" error>
              <webhooks :key="$route.params.id" />
            </flixCheckPermissions>
          </div>
        </template>
      </flixDashboard>
  </div>
</template>

<script>
export default {
  components: {
    webhooks () { return import('@/components/webhooks/') }
  }
}
</script>
